import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b6c2e264"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-green-500" }
const _hoisted_2 = { class: "product-container" }
const _hoisted_3 = { class: "image-container" }
const _hoisted_4 = { class: "info-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_ProductImage = _resolveComponent("ProductImage")!
  const _component_BaseImage = _resolveComponent("BaseImage")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseContainer, { class: "py-3 lg:py-5" }, {
      default: _withCtx(() => [
        _createVNode(_component_BackButton, { class: "back-button" }, {
          default: _withCtx(() => [
            _createVNode(_component_BaseHeading, {
              size: 4,
              class: "ml-1 lg:ml-2.5 leading-none",
              mobileSmaller: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('new_product_back_btn_lm')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.product.imageUrl)
              ? (_openBlock(), _createBlock(_component_ProductImage, {
                  key: 0,
                  src: _ctx.product.imageUrl,
                  alt: "Product",
                  class: "w-full"
                }, null, 8, ["src"]))
              : (_openBlock(), _createBlock(_component_BaseImage, {
                  key: 1,
                  icon: "new_product",
                  alt: "Product",
                  class: "w-full"
                }))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_BaseHeading, {
              size: 1,
              class: "text-white font-black"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.product.name), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_BaseHeading, {
              size: 1,
              class: "product-model"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.product.model), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_BaseButton, {
              size: 0,
              class: "link-button",
              component: "router-link",
              to: _ctx.routes.productDetails.path(_ctx.product.id)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BaseText, { class: "text-18" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('new_product_product_details')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_BaseIcon, {
                  icon: "arrow",
                  class: "icon"
                })
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}