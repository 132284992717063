
import { defineComponent, PropType } from 'vue';
import { routes } from '@/shared/constants/routes';
import { ProductDetails } from '@/shared/interfaces/productDetails';
import { useNumbers } from '@/shared/composables/useNumbers';
import { useTranslations } from '@/shared/composables/useTranslations';

export default defineComponent({
  name: 'FeaturedProduct',
  props: { product: { type: Object as PropType<ProductDetails>, required: true } },
  setup() {
    const { t } = useTranslations();
    const { cPerDay } = useNumbers();

    return { routes, t, cPerDay };
  },
});
