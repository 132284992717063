import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseContainer, { class: "pb-9 lg:pb-[11.2rem]" }, {
      default: _withCtx(() => [
        _createVNode(_component_BaseButton, {
          size: 2,
          class: "w-full",
          component: "router-link",
          to: _ctx.routes.productsList.path
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BaseText, { variant: "18-20" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('new_product_all_products')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_BaseIcon, {
              icon: "arrow",
              class: "ml-0.5 w-2.5 h-2 rotate-180"
            })
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _: 1
    })
  ]))
}