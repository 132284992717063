import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fbb6ccee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-green-500" }
const _hoisted_2 = { class: "grid-container" }
const _hoisted_3 = { class: "row-span-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeading = _resolveComponent("BaseHeading")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!
  const _component_ProductImage = _resolveComponent("ProductImage")!
  const _component_BaseImage = _resolveComponent("BaseImage")!
  const _component_BaseText = _resolveComponent("BaseText")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseContainer, { class: "py-3 lg:py-5" }, {
      default: _withCtx(() => [
        _createVNode(_component_BackButton, { class: "back-button" }, {
          default: _withCtx(() => [
            _createVNode(_component_BaseHeading, {
              size: 4,
              class: "ml-1 lg:ml-2.5 leading-none",
              mobileSmaller: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('new_product_back_btn')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseContainer, { class: "lg:pr-13" }, {
        default: _withCtx(() => [
          _createVNode(_component_BaseHeading, {
            size: 1,
            class: "text-white font-black"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.product.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BaseHeading, {
            size: 1,
            class: "product-model"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.product.model), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.product.imageUrl)
          ? (_openBlock(), _createBlock(_component_ProductImage, {
              key: 0,
              src: _ctx.product.imageUrl,
              alt: "Product",
              class: "product-image"
            }, null, 8, ["src"]))
          : (_openBlock(), _createBlock(_component_BaseImage, {
              key: 1,
              icon: "new_product",
              alt: "Product",
              class: "product-image"
            }))
      ]),
      _createVNode(_component_BaseContainer, { class: "lg:pr-13" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.pricing, (price, name) => {
            return (_openBlock(), _createElementBlock("div", {
              key: name,
              class: "card-pricing"
            }, [
              _createVNode(_component_BaseText, { class: "leading-none lg:text-18" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t(`product_details_price_${name}`)), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_BaseText, { class: "leading-none lg:text-18" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.cPerDay(price || 0)), 1)
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128)),
          _createVNode(_component_BaseButton, {
            size: 0,
            class: "my-3 lg:my-7 h-6 px-2 py-1.5",
            component: "router-link",
            to: _ctx.routes.productDetails.path(_ctx.product.id)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BaseText, { class: "text-18" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('new_product_product_details')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_BaseIcon, {
                icon: "arrow",
                class: "icon"
              })
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      })
    ])
  ]))
}