import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-screen" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Error = _resolveComponent("Error")!
  const _component_FeaturedProductWrapper = _resolveComponent("FeaturedProductWrapper")!
  const _component_ProductsInfiniteScroll = _resolveComponent("ProductsInfiniteScroll")!
  const _component_ProductsListButton = _resolveComponent("ProductsListButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isError)
      ? (_openBlock(), _createBlock(_component_Error, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.product)
            ? (_openBlock(), _createBlock(_component_FeaturedProductWrapper, {
                key: 0,
                product: _ctx.product
              }, null, 8, ["product"]))
            : _createCommentVNode("", true),
          (_ctx.products.length)
            ? (_openBlock(), _createBlock(_component_ProductsInfiniteScroll, {
                key: 1,
                products: _ctx.products,
                isLoadable: _ctx.isLoadable,
                page: _ctx.state.page,
                onLoadMore: _ctx.onLoadMore,
                class: "pt-5 lg:pt-0"
              }, null, 8, ["products", "isLoadable", "page", "onLoadMore"]))
            : _createCommentVNode("", true),
          _createVNode(_component_ProductsListButton)
        ]))
  ]))
}