import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeaturedProductLM = _resolveComponent("FeaturedProductLM")!
  const _component_FeaturedProduct = _resolveComponent("FeaturedProduct")!

  return (_ctx.isLm)
    ? (_openBlock(), _createBlock(_component_FeaturedProductLM, {
        key: 0,
        product: _ctx.product
      }, null, 8, ["product"]))
    : (_openBlock(), _createBlock(_component_FeaturedProduct, {
        key: 1,
        product: _ctx.product
      }, null, 8, ["product"]))
}