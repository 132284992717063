
import { defineComponent, PropType } from 'vue';
import { useVersions } from '@/shared/composables/useVersions';
import { ProductDetails } from '@/shared/interfaces/productDetails';
import FeaturedProduct from './FeaturedProduct.vue';
import FeaturedProductLM from './FeaturedProductLM.vue';

export default defineComponent({
  name: 'FeaturedProductWrapper',
  components: {
    FeaturedProduct,
    FeaturedProductLM,
  },
  props: { product: { type: Object as PropType<ProductDetails>, required: true } },

  setup() {
    const { isLm } = useVersions();
    return { isLm };
  },
});
