
import { defineComponent, onMounted, computed, reactive } from 'vue';
import { routes } from '@/shared/constants/routes';
import { useRouteParams } from '@/shared/composables/useRouteParams';
import { useTranslations } from '@/shared/composables/useTranslations';
import { useProductDetails } from '@/pages/ProductDetails/composables/useProductDetails';
import { useNewProducts } from './composables/useNewProducts';
import FeaturedProductWrapper from './components/FeaturedProductWrapper.vue';
import ProductsListButton from './components/ProductsListButton.vue';
import ProductsInfiniteScroll from '@/shared/components/InfiniteScroll/ProductsInfiniteScroll.vue';
import Error from '@/pages/Error.vue';

export default defineComponent({
  name: 'NewProducts',
  components: {
    ProductsInfiniteScroll,
    FeaturedProductWrapper,
    ProductsListButton,
    Error,
  },
  setup() {
    const pageSize = 12;
    const { t } = useTranslations();
    const { id } = useRouteParams();
    const { product, error: productError } = useProductDetails(id);
    const { products, error, getNewProducts, isLoading, isLastPage } = useNewProducts();
    const isLoadable = computed(() => isLoading.value || isLastPage.value);
    const isError = computed(() => productError.value && error.value);

    const state = reactive({
      page: 1,
    });

    onMounted(async () => {
      const options = { page: 1, pageSize };
      getNewProducts(options);
    });

    const onLoadMore = async () => {
      if (!isLastPage.value) {
        state.page += 1;
        const options = { page: state.page, pageSize };
        getNewProducts(options);
      }
    };

    return { routes, t, product, products, isLoadable, state, onLoadMore, isError };
  },
});
