
import { defineComponent } from 'vue';
import { routes } from '@/shared/constants/routes';
import { useTranslations } from '@/shared/composables/useTranslations';

export default defineComponent({
  name: 'ProductsListButton',
  setup() {
    const { t } = useTranslations();

    return { routes, t };
  },
});
